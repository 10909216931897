<template>
  <!-- <div>
    <div v-if="check('mastercard')" class="payment">
      <img src="~/static/core/icons/payment/mastercard.svg" alt="mastercard">
    </div>
    <div v-if="check('visa')" class="payment">
      <img src="~/static/core/icons/payment/visa.svg" class="img-min" alt="visa">
    </div>
    <div v-if="check('paypal')" class="payment">
      <img src="~/static/core/icons/payment/paypal-2.svg" class="img-min" alt="paypal">
    </div>
    <div v-if="check('payu')" class="payment">
      <img src="~/static/core/icons/payment/payu-2.svg" class="img-medium" alt="payu">
    </div>
    <div v-if="check('googlePay')" class="payment">
      <img src="~/static/core/icons/payment/google-pay.svg" class="img-min" alt="googlepay">
    </div>
    <div v-if="check('applePay')" class="payment">
      <img src="~/static/core/icons/payment/apple-pay.svg" class="img-min" alt="applepay">
    </div>
    <div v-if="check('klarna')" class="payment">
      <img src="~/static/core/icons/payment/klarna.svg" alt="klarna">
    </div>
  </div> -->
  <div class="checkout-payments">
    <div v-if="check('klarna')" class="payment">
      <img src="~/static/core/icons/payment/klarna-ratenkauf.svg" alt="klarna">
    </div>
    <div v-if="check('klarna')" class="payment">
      <img src="~/static/core/icons/payment/klarna-rechnung.svg" alt="klarna">
    </div>
    <div v-if="check('mastercard')" class="payment">
      <img src="~/static/core/icons/payment/mastercard.svg" alt="mastercard">
    </div>
    <div v-if="check('visa')" class="payment">
      <img src="~/static/core/icons/payment/visa.svg" alt="visa">
    </div>
    <div v-if="check('payu')" class="payment">
      <img src="~/static/core/icons/payment/payu.svg" alt="payu">
    </div>
    <div v-if="check('googlePay')" class="payment">
      <img src="~/static/core/icons/payment/gpay.svg" alt="googlepay">
    </div>
    <div v-if="check('applePay')" class="payment">
      <img src="~/static/core/icons/payment/mollie-applepay.svg" alt="applepay">
    </div>
    <div v-if="check('paypal')" class="payment">
      <img src="~/static/core/icons/payment/paypal.svg" alt="paypal">
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      paymentsIcons: process.env.paymentsIcons.split('|')
    }
  },
  methods: {
    check (payment) {
      return this.paymentsIcons.includes(payment)
    }
  }
}
</script>

<style lang="scss" scoped>
.checkout-payments {
  display: flex;
  align-items: center;
  justify-content: center;
  @include mobile {
    justify-content: center !important;
  }
  &.product-payments {
    .payment {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      overflow: hidden;
      img {
        height: 52px !important;
      }
    }
  }
  .payment {
    img {
      display: block;
      height: 80px;
      width: auto;
    }
  }
}
</style>
