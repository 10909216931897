<template>
  <div class="main-body">
    <layout-header />
    <div class="container main-container columns is-multiline main-content">
      <div class="column is-2-desktop is-12">
        <aside class="menu">
          <ul class="menu-list">
            <p class="menu-label">
              {{ $t('myAccount.orders') }}
            </p>
            <li>
              <nuxt-link :to="localePath('profile-orders')">
                {{ $t('myAccount.orders_history') }}
              </nuxt-link>
            </li>

            <li v-if="user && user.tradeCredit">
              <nuxt-link :to="localePath('profile-tradeCredit')">
                {{ $t('admin.tradeCredits.tradeCredit') }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath('profile-comments')">
                {{ $t('myAccount.comments') }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath('messenger')">
                {{ $t('messenger.title') }}
              </nuxt-link>
            </li>
          </ul>
          <p class="menu-label">
            {{ $t('myAccount.title') }}
          </p>
          <ul class="menu-list">
            <li>
              <nuxt-link :to="localePath('profile-edit')">
                {{ $t('myAccount.edit') }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath('profile-change-password')">
                {{ $t('myAccount.change_password') }}
              </nuxt-link>
            </li>
          </ul>
        </aside>
      </div>
      <div class="column is-10-desktop is-12">
        <slot name="header" />
        <nuxt />
      </div>
    </div>
    <footer-schuette v-if="project === 'schuette'" />
    <footer-rzaska v-if="project === 'rzaska'" />
    <global-layout />
  </div>
</template>

<script>
import GlobalLayout from 'marketplace-front-core/components/layout/GlobalLayout'
import LayoutHeader from '~/components/layout/Header'
import FooterSchuette from '~/components/layout/FooterSchuette'
import FooterRzaska from '~/components/layout/FooterRzaska'

export default {
  components: {
    LayoutHeader,
    FooterSchuette,
    FooterRzaska,
    GlobalLayout
  }
}
</script>
