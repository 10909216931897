<template>
  <div class="main-body">
    <layout-header :hide-menu="isSeller" />
    <div class="container main-container main-content new-messenger-layout">
      <nuxt />
    </div>
    <global-layout hide-scroll-top />
  </div>
</template>
<script>
import GlobalLayout from 'marketplace-front-core/components/layout/GlobalLayout'
import LayoutHeader from '~/components/layout/Header'
export default {
  components: {
    LayoutHeader,
    GlobalLayout
  },
  computed: {
    isSeller () {
      if (this.user) {
        const check = ['ROLE_SELLER_OWNER', 'ROLE_SELLER'].filter(val =>
          this.user.roles.includes(val)
        )

        return !!check.length
      }
      return false
    }
  }
}
</script>
<style lang="scss" scoped>
.main-body {
  @include touch {
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }
}
</style>
