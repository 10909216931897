<template>
  <a
    :href="
      localePath({
        name: 'product',
        params: { productName: productName, productId: productId },
      })
    "
    class="product-tile"
    @click.prevent="
      $store.dispatch('page/isMenuOpen', false);
      redirect();
    "
  >
    <div v-if="productId === 1" class="tile-icon">
      <svg id="a" xmlns="http://www.w3.org/2000/svg" width="38" height="44" viewBox="0 0 38 44">
        <rect
          x="2"
          width="34"
          height="40.5"
          rx="1"
          ry="1"
          style="fill: #fff; stroke-width: 0px"
        />
        <path
          d="M34.5,1.5v37.5H3.5V1.5h31M35,0H3c-.55,0-1,.45-1,1v38.5c0,.55.45,1,1,1h32c.55,0,1-.45,1-1V1c0-.55-.45-1-1-1Z"
          style="fill: #000; stroke-width: 0px"
        />
        <rect
          y="38"
          width="38"
          height="6"
          rx="1"
          ry="1"
          style="fill: #fff; stroke-width: 0px"
        />
        <path
          d="M36.5,39.5v3H1.5v-3h35M37,38H1c-.55,0-1,.45-1,1v4c0,.55.45,1,1,1h36c.55,0,1-.45,1-1v-4c0-.55-.45-1-1-1Z"
          style="fill: #000; stroke-width: 0px"
        />
        <path
          d="M30,5.5v28.5H8V5.5h22M30.5,4H7.5c-.55,0-1,.45-1,1v29.5c0,.55.45,1,1,1h23c.55,0,1-.45,1-1V5c0-.55-.45-1-1-1Z"
          style="fill: #000; stroke-width: 0px"
        />
        <rect
          x="6"
          y="8"
          width="26"
          height="3"
          style="fill: #e32f31; stroke-width: 0px"
        />
        <rect
          x="6"
          y="11"
          width="26"
          height="3"
          style="fill: #a20305; stroke-width: 0px"
        />
        <rect
          x="6"
          y="14"
          width="26"
          height="3"
          style="fill: #e32f31; stroke-width: 0px"
        />
        <rect
          x="6"
          y="20"
          width="26"
          height="3"
          style="fill: #e32f31; stroke-width: 0px"
        />
        <rect
          x="6"
          y="17"
          width="26"
          height="3"
          style="fill: #a20305; stroke-width: 0px"
        />
        <path
          d="M31.5,8.5v14H6.5v-14h25M32,7H6c-.55,0-1,.45-1,1v15c0,.55.45,1,1,1h26c.55,0,1-.45,1-1v-15c0-.55-.45-1-1-1Z"
          style="fill: #000; stroke-width: 0px"
        />
        <path
          d="M23.61,31.91l4.54-4.54c.2-.2.51-.2.71,0h0c.2.2.2.51,0,.71l-4.54,4.54c-.2.2-.51.2-.71,0h0c-.2-.2-.2-.51,0-.71Z"
          style="fill: #000; stroke-width: 0px"
        />
        <path
          d="M26.44,31.97l1.71-1.71c.2-.2.51-.2.71,0h0c.2.2.2.51,0,.71l-1.71,1.71c-.2.2-.51.2-.71,0h0c-.2-.2-.2-.51,0-.71Z"
          style="fill: #000; stroke-width: 0px"
        />
      </svg>
    </div>
    <div v-if="productId === 917" class="tile-icon">
      <svg id="a" xmlns="http://www.w3.org/2000/svg" width="38" height="44" viewBox="0 0 38 44">
        <rect
          x="2"
          width="34"
          height="40.5"
          rx="1"
          ry="1"
          style="fill: #fff; stroke-width: 0px"
        />
        <path
          d="M34.5,1.5v37.5H3.5V1.5h31M35,0H3c-.55,0-1,.45-1,1v38.5c0,.55.45,1,1,1h32c.55,0,1-.45,1-1V1c0-.55-.45-1-1-1Z"
          style="fill: #000; stroke-width: 0px"
        />
        <rect
          y="38"
          width="38"
          height="6"
          rx="1"
          ry="1"
          style="fill: #fff; stroke-width: 0px"
        />
        <path
          d="M36.5,39.5v3H1.5v-3h35M37,38H1c-.55,0-1,.45-1,1v4c0,.55.45,1,1,1h36c.55,0,1-.45,1-1v-4c0-.55-.45-1-1-1Z"
          style="fill: #000; stroke-width: 0px"
        />
        <path
          d="M30,5.5v28.5H8V5.5h22M30.5,4H7.5c-.55,0-1,.45-1,1v29.5c0,.55.45,1,1,1h23c.55,0,1-.45,1-1V5c0-.55-.45-1-1-1Z"
          style="fill: #000; stroke-width: 0px"
        />
        <rect
          x="6"
          y="4"
          width="26"
          height="19"
          style="fill: #e32f31; stroke-width: 0px"
        />
        <path
          d="M31.5,4.5v18H6.5V4.5h25M32,3H6c-.55,0-1,.45-1,1v19c0,.55.45,1,1,1h26c.55,0,1-.45,1-1V4c0-.55-.45-1-1-1Z"
          style="fill: #000; stroke-width: 0px"
        />
        <rect
          x="2"
          width="34"
          height="5"
          rx="1"
          ry="1"
          style="fill: #fff; stroke-width: 0px"
        />
        <path
          d="M34.5,1.5v2H3.5V1.5h31M35,0H3c-.55,0-1,.45-1,1v3c0,.55.45,1,1,1h32c.55,0,1-.45,1-1V1c0-.55-.45-1-1-1Z"
          style="fill: #000; stroke-width: 0px"
        />
        <path
          d="M23.61,31.91l4.54-4.54c.2-.2.51-.2.71,0h0c.2.2.2.51,0,.71l-4.54,4.54c-.2.2-.51.2-.71,0h0c-.2-.2-.2-.51,0-.71Z"
          style="fill: #000; stroke-width: 0px"
        />
        <path
          d="M26.44,31.97l1.71-1.71c.2-.2.51-.2.71,0h0c.2.2.2.51,0,.71l-1.71,1.71c-.2.2-.51.2-.71,0h0c-.2-.2-.2-.51,0-.71Z"
          style="fill: #000; stroke-width: 0px"
        />
      </svg>
    </div>
    <div v-if="productId === 2458" class="tile-icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 44">
        <rect x="11.75" y="3" width="10.5" height="1.5" />
        <rect x="29.75" y="3" width="10.5" height="1.5" />
        <path
          d="M45.25,1A2.76,2.76,0,0,0,42.6,3h-.85V0L38,1.29l-3-1-3,1L28.25,0V3h-4.5V0L20,1.29l-3-1-3,1L10.25,0V3H9.4a2.75,2.75,0,1,0,0,1.5h.85V44h13.5V4.5h4.5V44h13.5V4.5h.85A2.75,2.75,0,1,0,45.25,1ZM7.75,4.5A1.25,1.25,0,1,1,7.5,2.75,1.48,1.48,0,0,1,7.75,3,1.26,1.26,0,0,1,7.75,4.5Zm14.5,38H11.75V2.1L14,2.88l3-1,3,1,2.25-.78Zm18,0H29.75V2.1L32,2.88l3-1,3,1,2.25-.78Zm5-37.5a1.25,1.25,0,1,1,1.22-1A1.25,1.25,0,0,1,45.25,5Z"
        />
        <path
          d="M8,3.75a1.27,1.27,0,0,1-.25.75A1.25,1.25,0,1,1,7.5,2.75,1.48,1.48,0,0,1,7.75,3,1.27,1.27,0,0,1,8,3.75Z"
          style="fill: #fff"
        />
        <path
          d="M46.5,3.75A1.25,1.25,0,1,1,45.25,2.5a1.27,1.27,0,0,1,.39.06A1.25,1.25,0,0,1,46.5,3.75Z"
          style="fill: #fff"
        />
        <path d="M14,2.88V42.5H11.75V2.1Z" style="fill: #e32f31" />
        <path d="M17,1.84V42.5H14V2.88Z" style="fill: #a20305" />
        <path d="M22.25,2.1V42.5H20V2.88Z" style="fill: #a20305" />
        <path d="M20,2.88V42.5H17V1.84Z" style="fill: #e32f31" />
        <path d="M32,2.88V42.5H29.75V2.1Z" style="fill: #e32f31" />
        <path d="M35,1.84V42.5H32V2.88Z" style="fill: #a20305" />
        <path d="M40.25,2.1V42.5H38V2.88Z" style="fill: #a20305" />
        <path d="M38,2.88V42.5H35V1.84Z" style="fill: #e32f31" />
      </svg>
    </div>
    <div v-if="productId === 4030" class="tile-icon">
      <!-- Generator: Adobe Illustrator 28.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
      <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
        <defs>
          <style>
            .b {
              fill: #e32f31;
            }
            .b,
            .c,
            .d,
            .e {
              stroke-width: 0px;
            }
            .c {
              fill: #000;
            }
            .d {
              fill: #a20305;
            }
            .e {
              fill: #fff;
            }
          </style>
        </defs>
        <path
          class="c"
          d="M111.9,42.8c-4,0-7.8-1.5-10.8-4.2-3.2-2.9-5.1-6.9-5.3-11.2-.4-8.5,6-15.9,14.5-16.8,1.9-.2,4.5.1,6.1.4,1,.2,1.7,1.1,1.6,2.1,0,1.1-.9,2-2,2-2.5.1-5,1.4-6.7,3.3-1.4,1.7-2.1,3.7-1.9,5.7.4,4.2,4,7.4,8.2,7.4h.1c4.1,0,7.5-3,8.1-7.1.1-.6.4-1.1.9-1.4s1.1-.5,1.6-.3h0c.9.2,1.6.9,1.7,1.8.1.4.1.8.1,1.2.5,8.9-6.4,16.5-15.3,17-.4.1-.7.1-.9.1ZM111.1,13.5h-.5c-7,.7-12.2,6.7-11.9,13.7.2,3.5,1.7,6.8,4.3,9.2,2.6,2.4,6,3.6,9.6,3.4,6-.3,10.8-4.5,12.1-10.1-2,2.9-5.3,4.7-9,4.7-5.7.1-10.7-4.4-11.3-10.1-.3-2.8.6-5.6,2.6-7.9,1.1-1.1,2.5-2.2,4.1-2.9ZM115.8,12.3h0Z"
        />
        <path
          class="c"
          d="M113.1,58.2h-7.5c-.8,0-1.5-.7-1.5-1.5s.7-1.5,1.5-1.5h7.5c.8,0,1.5.7,1.5,1.5s-.6,1.5-1.5,1.5Z"
        />
        <path
          class="c"
          d="M109.4,62c-.8,0-1.5-.7-1.5-1.5v-7.5c0-.8.7-1.5,1.5-1.5s1.5.7,1.5,1.5v7.5c-.1.8-.7,1.5-1.5,1.5Z"
        />
        <path
          class="c"
          d="M86.2,10.7h-7.5c-.8,0-1.5-.7-1.5-1.5s.7-1.5,1.5-1.5h7.5c.8,0,1.5.7,1.5,1.5s-.7,1.5-1.5,1.5Z"
        />
        <path
          class="c"
          d="M82.4,14.5c-.8,0-1.5-.7-1.5-1.5v-7.5c0-.8.7-1.5,1.5-1.5s1.5.7,1.5,1.5v7.5c0,.8-.7,1.5-1.5,1.5Z"
        />
        <path
          class="e"
          d="M85,27.7v38.4h-5.33l-2.77-14.6c-.1-.4-.3-.8-.6-1-.7-.6-4.9-2.5-25.9-2.5s-25.4,2.1-25.9,2.3-.8.6-.9,1.1l-3.32,14.7h-4.48V27.7h69.2Z"
        />
        <path
          class="c"
          d="M101,93.1l-12.7-25.7V27.7c0-1.8-1.5-3.3-3.3-3.3H15.8c-1.8,0-3.3,1.5-3.3,3.3v40c0,.14.02.27.05.4L.1,93.1c-.13.34-.18.72-.1,1.08v12.52c0,1.3.58,2.48,1.5,3.29v9.01c0,2.1,1.7,3.8,3.8,3.8h91c2.1,0,3.8-1.7,3.8-3.8v-9.5s0-.03,0-.05h0c.61-.77.95-1.72.91-2.75v-12.19c.2-.46.18-.97,0-1.41ZM15.8,27.7h69.2v38.4h-5.33l-2.77-14.6c-.1-.4-.3-.8-.6-1-.7-.6-4.9-2.5-25.9-2.5s-25.4,2.1-25.9,2.3-.8.6-.9,1.1l-3.32,14.7h-4.48V27.7ZM77.1,70c-3.5.4-12.5,1.3-27.2,1.3s-23.5-.9-27.1-1.3l.13-.6.35-1.6.37-1.7,2.85-13c2-.5,8.3-1.8,23.9-1.8,16.4,0,21.9,1.2,23.5,1.7l2.47,13.1.32,1.7.3,1.6.11.6ZM15.5,69.4h4.04l-.34,1.5c-.1.5-.1.9.2,1.3.3.4.7.6,1.1.7.4.1,10,1.6,29.4,1.6s29-1.5,29.3-1.6c.5-.1.8-.3,1.1-.7.3-.3.4-.8.3-1.2l-.3-1.6h5.2l11.4,22.8H4.3l11.2-22.8ZM97.3,119c0,.5-.4.9-.9.9H5.3c-.5,0-.9-.4-.9-.9v-7.9h92.3c.2,0,.41,0,.6-.05v7.95ZM97.8,106.7c0,.6-.5,1.1-1.1,1.1H4.4c-.6,0-1.1-.5-1.1-1.1v-11.2h94.5v11.2Z"
        />
        <path
          class="b"
          d="M96.9,92.2H4.3l11.2-22.8h4.04l-.34,1.5c-.1.5-.1.9.2,1.3.3.4.7.6,1.1.7.4.1,10,1.6,29.4,1.6s29-1.5,29.3-1.6c.5-.1.8-.3,1.1-.7.3-.3.4-.8.3-1.2l-.3-1.6h5.2l11.4,22.8Z"
        />
        <path
          class="d"
          d="M97.8,95.5v11.2c0,.6-.5,1.1-1.1,1.1H4.4c-.6,0-1.1-.5-1.1-1.1v-11.2h94.5Z"
        />
        <path
          class="e"
          d="M97.3,111.05v7.95c0,.5-.4.9-.9.9H5.3c-.5,0-.9-.4-.9-.9v-7.9h92.3c.2,0,.41,0,.6-.05Z"
        />
        <path
          class="b"
          d="M77.1,70c-3.5.4-12.5,1.3-27.2,1.3s-23.5-.9-27.1-1.3l.13-.6.35-1.6.37-1.7,2.85-13c2-.5,8.3-1.8,23.9-1.8,16.4,0,21.9,1.2,23.5,1.7l2.47,13.1.32,1.7.3,1.6.11.6Z"
        />
      </svg>
    </div>
    <span v-html="name" />
  </a>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    productName: {
      type: String,
      default: "",
    },
    productId: {
      type: Number,
      default: null,
    },
    disableRedirect: {
      type: Boolean,
    },
  },
  methods: {
    redirect() {
      if (!this.disableRedirect) {
        this.$emit("update:disableRedirect", true);
        this.$router.push(
          this.localePath({
            name: "product",
            params: {
              productName: this.productName,
              productId: this.productId,
            },
          }),
          () => {
            this.$emit("update:disableRedirect", false);
          },
          () => {
            this.$emit("update:disableRedirect", false);
          }
        );
      }
    },
  },
};
</script>
